import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
const customLoader = `<style>
.ring
{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  background:transparent;
  border:3px solid #97294d;
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  color:#97294d;
  letter-spacing:4px;
  text-transform:uppercase;
  text-shadow:0 0 10px #97294d;
  box-shadow:0 0 20px rgba(0,0,0,.5);

}
.ring:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid #97294d;
  border-right:3px solid #97294d;
  border-radius:50%;
  animation:animateC 2s linear infinite;
}
.ring span
{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate 2s linear infinite;
}
.ring span:before
{
  content:'';
  position:absolute;
  width:16px;
  height:16px;
  border-radius:50%;
  background:#97294d;
  top:-6px;
  right:-8px;
  box-shadow:0 0 20px #97294d;
}
@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}
</style>
<div class="ring">Loading
  <span></span>
</div>`;
const customError = "<h1 align='center'>Oops! App isn't working right now.</h1>";

const data = {
  loaders: {
    customLoader,
  },
  props: {
    user: { fname: "Jane", lname: "John" },
  },
  errors: {
    customError,
  },
};
const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
